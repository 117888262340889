import { HStack, Box, Center, VStack, Flex, Image } from '@chakra-ui/react';
import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import IconCheckGreen from '../../../../images/icons/icon-check-green.svg';
import IconCheckBlur from '../../../../images/icons/icon-check-blur.svg';
import {
  DataDeviceSkyTrak,
  DataMembershipSkyTrak,
  MembershipSkyTrak,
} from '../../../../models/skytrak';

export const FeatureBasic = () => {
  const intl = useIntl();

  const arrayOptionsPlan = useMemo(() => {
    const array = [
      'Drivingrange',
      'SwingLab',
      'Connect3rdParty',
      'CoursePlay',
      'UnlockAddonFeature',
    ];

    return array;
  }, []);

  return (
    <VStack spacing={'12px'} width={'100%'} alignItems={'flex-start'}>
      {arrayOptionsPlan.map(
        (title: string, idx: number) =>
          idx < 4 && (
            <HStack key={idx} spacing={'8px'} alignItems={'flex-start'}>
              <Center minWidth={{ mobile: '24px' }}>
                {idx === 0 ? (
                  <Image src={IconCheckGreen} />
                ) : (
                  <Image src={IconCheckBlur} />
                )}
              </Center>
              <Box
                fontWeight={600}
                fontSize={14}
                lineHeight={{ mobile: '20px' }}
                pt={'2px'}
              >
                {intl.formatMessage({ id: title })}
              </Box>
            </HStack>
          )
      )}
    </VStack>
  );
};

export const FeatureEssentialTrial = () => {
  const intl = useIntl();

  const arrayOptionsPlan = useMemo(() => {
    const array = [
      'Drivingrange',
      'SwingLab',
      'Connect3rdParty',
      'NEWImprovementRanges',
      'UnlockAddonFeature',
    ];

    return array;
  }, []);

  return (
    <VStack spacing={'12px'} width={'100%'} alignItems={'flex-start'}>
      {arrayOptionsPlan.map((title: string, idx: number) => (
        <HStack key={idx} spacing={'8px'} alignItems={'flex-start'}>
          <Center minWidth={{ mobile: '24px' }}>
            {idx < 4 ? (
              <Image src={IconCheckGreen} />
            ) : (
              <Image src={IconCheckBlur} />
            )}
          </Center>
          <Box
            fontWeight={600}
            fontSize={14}
            lineHeight={{ mobile: '20px' }}
            pt={'2px'}
          >
            {intl.formatMessage({ id: title })}
          </Box>
        </HStack>
      ))}
    </VStack>
  );
};

export const FeatureEssential = () => {
  const intl = useIntl();

  const arrayOptionsPlan = useMemo(() => {
    const array = [
      'Drivingrange',
      'SwingLab',
      'Connect3rdParty',
      'NEWImprovementRanges',
      'UnlockAddonFeature',
    ];

    return array;
  }, []);

  return (
    <VStack spacing={'12px'} width={'100%'} alignItems={'flex-start'}>
      {arrayOptionsPlan.map((title: string, idx: number) => (
        <HStack key={idx} spacing={'8px'} alignItems={'flex-start'}>
          <Center minWidth={{ mobile: '24px' }}>
            <Image src={IconCheckGreen} />
          </Center>
          <Flex
            fontWeight={600}
            fontSize={14}
            lineHeight={{ mobile: '20px' }}
            pt={{ mobile: '2px' }}
            display={'inline'}
          >
            <Box display={'inline-flex'} mr={'6px'}>
              {intl.formatMessage({ id: title })}
            </Box>
            {/* {title === 'CoursePlay' && (
              <Box
                display={'inline-flex'}
                fontStyle={'italic'}
                fontWeight={400}
              >
                {intl.formatMessage({ id: 'CoursePlayFeature' })}
              </Box>
            )} */}
          </Flex>
        </HStack>
      ))}
    </VStack>
  );
};

export const FeatureCourse = () => {
  const intl = useIntl();

  const arrayCoursePlayOptions = useMemo(() => {
    const array = [
      'CoursePlayFeature1',
      'QuailHollowClub',
      'SweetensCoveGolfClub',
      'CoursePlayFeature4',
    ];

    return array;
  }, []);

  return (
    <VStack spacing={'12px'} width={'100%'} alignItems={'flex-start'}>
      {arrayCoursePlayOptions.map((title: string, idx: number) => (
        <HStack key={idx} spacing={'8px'} alignItems={'flex-start'}>
          <Center minWidth={{ mobile: '24px' }}>
            <Image src={IconCheckGreen} />
          </Center>
          <Box
            fontWeight={600}
            fontSize={14}
            lineHeight={{ mobile: '20px' }}
            pt={'2px'}
          >
            {intl.formatMessage({ id: title })}
          </Box>
        </HStack>
      ))}
    </VStack>
  );
};

export const FeatureWGT = () => {
  const intl = useIntl();

  const arrayWGTOptions = useMemo(() => {
    const array = ['WGTFeature1', 'WGTFeature2', 'WGTFeature3', 'WGTFeature4'];

    return array;
  }, []);

  return (
    <VStack spacing={'12px'} width={'100%'} alignItems={'flex-start'}>
      {arrayWGTOptions.map((title: string, idx: number) => (
        <HStack key={idx} spacing={'8px'} alignItems={'flex-start'}>
          <Center minWidth={{ mobile: '24px' }}>
            {idx === 3 ? (
              <Image src={IconCheckBlur} />
            ) : (
              <Image src={IconCheckGreen} />
            )}
          </Center>
          <Box
            fontWeight={600}
            fontSize={14}
            lineHeight={{ mobile: '20px' }}
            pt={'2px'}
          >
            {intl.formatMessage({ id: title })}
          </Box>
        </HStack>
      ))}
    </VStack>
  );
};

type ReturnNameBySkuProps = {
  item: DataMembershipSkyTrak;
};

export const SKU_UPGRADE =
  /Upgrade PIP PP|Upgrade-Old-GIP-to-New-PIP|Upgrade-PIP-1505|Upgrade to Play and Improve Package/;
export const SKU_COURSEPLAY = /Course Play|CP-STD/;
export const SKU_PIP = /PLAY-IMPROVE|GipPlay|GIP-Play|GIP-PLAY|PLAY IMPROVE/;
export const SKU_GIP =
  /GameImp|GameImprove|GAMEIMPROVE|Game Improvement|Game-Improvement/;

export const ReturnNameBySku = ({ item }: ReturnNameBySkuProps) => {
  const intl = useIntl();

  const returnNameBySku = useMemo(() => {
    if (item.membershipTypeName.toLowerCase().match(/trial|basic/)) {
      return intl.formatMessage({ id: 'Basic' });
    } else if (
      item.sku.match(SKU_GIP) ||
      item.membershipTypeName.match(SKU_GIP)
    ) {
      return intl.formatMessage({ id: 'Essential' });
    } else if (item.sku.match(SKU_COURSEPLAY)) {
      return intl.formatMessage({ id: 'CoursePlayStandard' });
    } else if (item.membershipTypeName.match(/E6WGTSoftwarePack/)) {
      return intl.formatMessage({ id: 'E6WGTSoftwarePack' });
    } else if (
      item.sku.match(SKU_UPGRADE) &&
      !(['Activated', 'Unlink'].includes(item.state) || item.referenceLegacyId)
    ) {
      return intl.formatMessage({ id: 'E6WGTSoftwarePack' });
    } else if (
      (item.sku.match(SKU_UPGRADE) &&
        (['Activated', 'Unlink'].includes(item.state) ||
          item.referenceLegacyId)) ||
      item.sku.match(SKU_PIP) ||
      ['PIP'].includes(item.sku) ||
      item.membershipTypeName.match(SKU_PIP)
    ) {
      return intl.formatMessage({
        id: 'EssentialE6WGTPack',
      });
    }
    return intl.formatMessage({
      id: item.membershipTypeName.replace(/\s/g, ''),
      defaultMessage: item.membershipTypeName,
    });
  }, [intl, item]);

  return <Box display={'inline'}>{returnNameBySku}</Box>;
};

type ReturnNameByNameProps = {
  item: DataDeviceSkyTrak;
};

export const ReturnNameByName = ({ item }: ReturnNameByNameProps) => {
  const intl = useIntl();

  const returnNameByName = useMemo(() => {
    if (item.membershipTypeName.toLowerCase().match(/trial/)) {
      return intl.formatMessage({ id: 'EssentialTrial' });
    } else if (item.membershipTypeName.toLowerCase().match(/basic/)) {
      return intl.formatMessage({ id: 'Basic' });
    } else if (item.membershipTypeName.match(SKU_GIP)) {
      return intl.formatMessage({ id: 'Essential' });
    } else if (item.membershipTypeName.match(/Course Play/)) {
      return intl.formatMessage({ id: 'CoursePlayStandard' });
    } else if (item.membershipTypeName.match(SKU_PIP)) {
      return intl.formatMessage({ id: 'EssentialE6WGTPack' });
    }

    return intl.formatMessage({
      id: item.membershipTypeName.replace(/\s/g, ''),
      defaultMessage: item.membershipTypeName,
    });
  }, [intl, item.membershipTypeName]);

  return <Box display={'inline'}>{returnNameByName}</Box>;
};

export const checkMode = (
  dataMembershipSkyTrak: MembershipSkyTrak | null,
  deviceEsn: DataDeviceSkyTrak[] | null
) => {
  if (
    dataMembershipSkyTrak &&
    dataMembershipSkyTrak.currentMemberships &&
    dataMembershipSkyTrak.currentMemberships.length > 0
  ) {
    const check = dataMembershipSkyTrak.currentMemberships.find(
      (item) =>
        (['New', 'Unlink'].includes(item.state) ||
          new Date(item.expireDate) > new Date()) &&
        item.membershipTypeName &&
        ['skytrak', 'legacy'].includes(item.source) &&
        !['Upgraded', 'Refund'].includes(item.state) &&
        ((item.sku.match(SKU_UPGRADE) &&
          (['Activated', 'Unlink'].includes(item.state) ||
            item.referenceLegacyId)) ||
          item.membershipTypeName.toLowerCase().match(/Game|Play|Essential/i))
    );
    if (check) {
      return 'Essential';
    }
  }
  if (deviceEsn && deviceEsn.length > 0) {
    const check = deviceEsn.find((device) =>
      device.membershipTypeName.toLowerCase().match(/trial/)
    );
    if (check) {
      return 'Trial';
    }
  }
  return 'Basic';
};

export const filterMembershipFunc = (
  dataMembershipSkyTrak: MembershipSkyTrak | null,
  idMembershipRenewed: number,
  idMembershipUpgraded: number
) => {
  if (
    dataMembershipSkyTrak &&
    dataMembershipSkyTrak.currentMemberships &&
    dataMembershipSkyTrak.currentMemberships.length > 0
  ) {
    return dataMembershipSkyTrak.currentMemberships.filter(
      (item) =>
        ((item.sku &&
          !item.sku.toLowerCase().match(/st-basic|st basic|trial/i) &&
          item.sku.toLowerCase() !== 'basic') ||
          !item.sku) &&
        (['New', 'Unlink'].includes(item.state) ||
          new Date(item.expireDate) > new Date()) &&
        ['legacy', 'skytrak'].includes(item.source) &&
        !['Upgraded', 'Refund'].includes(item.state) &&
        item.id !== idMembershipRenewed &&
        item.id !== idMembershipUpgraded
    );
  }
  return [];
};

export const filterCoursePlayFunc = (
  dataMembershipSkyTrak: MembershipSkyTrak | null,
  idMembershipRenewed: number,
  idMembershipUpgraded: number
) => {
  if (
    dataMembershipSkyTrak &&
    dataMembershipSkyTrak.currentMemberships &&
    dataMembershipSkyTrak.currentMemberships.length > 0
  ) {
    return dataMembershipSkyTrak.currentMemberships.filter(
      (item) =>
        (['New', 'Unlink'].includes(item.state) ||
          new Date(item.expireDate) > new Date()) &&
        ['simulation'].includes(item.source) &&
        !['Upgraded', 'Refund'].includes(item.state) &&
        item.sku.match(/Course Play|CP-STD/) &&
        item.id !== idMembershipRenewed &&
        item.id !== idMembershipUpgraded
    );
  }
  return [];
};

export const filterSimulationFunc = (
  dataMembershipSkyTrak: MembershipSkyTrak | null,
  idMembershipRenewed: number,
  idMembershipUpgraded: number
) => {
  if (
    dataMembershipSkyTrak &&
    dataMembershipSkyTrak.currentMemberships &&
    dataMembershipSkyTrak.currentMemberships.length > 0
  ) {
    return dataMembershipSkyTrak.currentMemberships.filter(
      (item) =>
        (['New', 'Unlink'].includes(item.state) ||
          new Date(item.expireDate) > new Date()) &&
        ['simulation'].includes(item.source) &&
        !['Upgraded', 'Refund'].includes(item.state) &&
        !item.sku.match(/Course Play|CP-STD/) &&
        item.id !== idMembershipRenewed &&
        item.id !== idMembershipUpgraded
    );
  }
  return [];
};

export const checkDomainJP = () => {
  if (window.location.hostname.match(/skytrakgolf.jp|localhost/)) {
    return true;
  }
  return false;
};
