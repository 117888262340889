import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import {
  DetailClubFitting,
  DetailClubFittingItem,
  ListTecFitData,
  SelectedClub,
} from '../models/clubFitting';
import request from '../utils/request';
import { RootState } from '../utils/store';
import { signOut } from './user';

export const fetchTecfitData = createAsyncThunk(
  'techfit/techfitData',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await request.get('/api/v2/tecfit/session');
      return data;
    } catch (e) {
      let error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const fetchTecfitV3Data = createAsyncThunk(
  'techfit/fetchTecfitV3Data',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await request.get('/api/v3/tecfit/session');
      return data;
    } catch (e) {
      let error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

interface TecfitData {
  items: any;
  code: number;
}

interface LoadDetail {
  type: string;
  created: string;
  selected: string;
}

export const fetchTecfitDetail = createAsyncThunk(
  'detail/techfitDetail',
  async (payload: LoadDetail, { rejectWithValue }) => {
    try {
      const { data } = await request.get(
        `/api/tecfit/session/detail?type=${payload.type}&created=${payload.created}&selected=${payload.selected}&renderFittingChart=true`
      );
      return data;
    } catch (e) {
      let error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const fetchTecfitDetailV3 = createAsyncThunk(
  'detail/fetchTecfitDetailV3',
  async (payload: LoadDetail, { rejectWithValue }) => {
    try {
      const { data } = await request.get(
        `/api/tecfit/session/detail?type=${payload.type}&created=${payload.created}&selected=${payload.selected}&renderFittingChart=true`
      );
      let dataCustom = {
        ...data,
        data: {
          ...data.data,
          created: payload.created,
          selected: payload.selected,
        },
      };
      return dataCustom;
    } catch (e) {
      let error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

interface TecfitState {
  loadingData: boolean;
  error: null | number;
  code: null | TecfitData;
  selectedClubFitting: null | DetailClubFittingItem;
  selectedQuickView: null | DetailClubFittingItem;
  dataClubFitting: null | DetailClubFitting[];
  loadingDetail: boolean;
  detailClubFitting: null | SelectedClub;
  isRedirect: boolean;
  statusOpenQuickViewClubFitting: boolean;
  listTecfitData: ListTecFitData | null;
  detailClubFittingItemV3: SelectedClub[];
  loadingDetailV3: boolean;
}

const initialState: TecfitState = {
  loadingData: false,
  error: null,
  code: null,
  selectedClubFitting: null,
  selectedQuickView: null,
  dataClubFitting: null,
  loadingDetail: false,
  detailClubFitting: null,
  isRedirect: false,
  statusOpenQuickViewClubFitting: false,
  listTecfitData: null,
  detailClubFittingItemV3: [],
  loadingDetailV3: false,
};

export const tecfitSlice = createSlice({
  name: 'techfit',
  initialState,
  reducers: {
    changeSelectClubFitting: (state, action) => {
      state.selectedClubFitting = action.payload;
    },
    changeSelectClubFittingQuickView: (state, action) => {
      state.selectedQuickView = action.payload;
    },
    changeClubFittingStatusRedirect: (state, action) => {
      state.isRedirect = action.payload;
    },
    changeStatusQuickViewClubFitting: (state, action) => {
      state.statusOpenQuickViewClubFitting = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchTecfitData.pending, (state, action) => {
        state.loadingData = true;
      })

      .addCase(fetchTecfitData.fulfilled, (state, action) => {
        const { data } = action.payload;
        state.dataClubFitting = data;
        state.code = action.payload;
        state.loadingData = false;
      })
      .addCase(fetchTecfitV3Data.pending, (state, action) => {
        state.loadingData = true;
      })
      .addCase(fetchTecfitV3Data.fulfilled, (state, action) => {
        const { data } = action.payload;
        state.listTecfitData = data;
        state.loadingData = false;
      })
      .addCase(fetchTecfitDetail.pending, (state, action) => {
        state.loadingDetail = true;
      })
      .addCase(fetchTecfitDetail.fulfilled, (state, action) => {
        const { data } = action.payload;
        state.detailClubFitting = data;
        state.loadingDetail = false;
      })
      .addCase(fetchTecfitDetailV3.pending, (state, action) => {
        state.loadingDetailV3 = true;
      })
      .addCase(fetchTecfitDetailV3.fulfilled, (state, action) => {
        const { data } = action.payload;
        const idx = state.detailClubFittingItemV3.findIndex(
          (i) =>
            i.selected === data.selected &&
            i.created === data.created &&
            i.type === data.type
        );
        if (idx >= 0) {
          state.detailClubFittingItemV3[idx] = data;
        } else {
          state.detailClubFittingItemV3.push(data);
        }
        state.loadingDetailV3 = false;
      })
      .addCase(signOut, () => ({ ...initialState }))
      .addMatcher(
        (action) => action.type.endsWith('/rejected'),
        (state, action) => {
          state.error = action.payload;
          state.loadingDetailV3 = false;
        }
      );
  },
});

export const {
  changeStatusQuickViewClubFitting,
  changeSelectClubFitting,
  changeSelectClubFittingQuickView,
  changeClubFittingStatusRedirect,
} = tecfitSlice.actions;

export const selectSampleTecfit = (state: RootState) =>
  state.techfit.code as TecfitData;
export const loadingTecfitData = (state: RootState) =>
  state.techfit.loadingData;
export const selectClubFitting = (state: RootState) =>
  state.techfit.selectedClubFitting;
export const selectClubFittingQuickView = (state: RootState) =>
  state.techfit.selectedQuickView;
export const selectClubFittingData = (state: RootState) =>
  state.techfit.dataClubFitting;
export const loadingTecfitDetail = (state: RootState) =>
  state.techfit.loadingDetail;
export const selectClubFittingDetail = (state: RootState) =>
  state.techfit.detailClubFitting;
export const selectClubFittingStatusRedirect = (state: RootState) =>
  state.techfit.isRedirect;
export const selectStatusQuickViewClubFitting = (state: RootState) =>
  state.techfit.statusOpenQuickViewClubFitting;
export const selectListTecfitData = (state: RootState) =>
  state.techfit.listTecfitData;
export const selectListDetailClubFittingItemV3 = (state: RootState) =>
  state.techfit.detailClubFittingItemV3;
export const selectLoadingListDetailClubFittingItemV3 = (state: RootState) =>
  state.techfit.loadingDetailV3;
export default tecfitSlice.reducer;
